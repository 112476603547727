<template>
    <b-container fluid>
        <b-row class="section-title d-flex justify-content-between" no-gutters>
            <b-col cols="12" md="auto" class="d-flex justify-content-center">
                <h2>Configuration du template</h2>
            </b-col>
            <b-col
                cols="12"
                md="auto"
                class="d-flex justify-content-center align-self-center"
            >
                <b-button
                    size="sm"
                    variant="outline-danger"
                    class="m-auto"
                    v-b-modal.ModalConfirmDelete
                >
                    <b-icon-trash />&nbsp;Supprimer la configuration
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                <FormTemplateConfig
                    :key="templateConfig.id"
                    :templateconfig="templateConfig"
                />
            </b-col>
        </b-row>

        <!-- Modals -->
        <ModalConfirmDelete @confirmed="onDeleteConfirmed" />
    </b-container>
</template>

<script>
import FormTemplateConfig from "@/components/model/templateconfig/FormTemplateConfig";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete";
export default {
    components: {
        FormTemplateConfig,
        ModalConfirmDelete,
    },

    data: function() {
        return {
            applicationId: this.$route.params.applicationId,
            templateConfigId: this.$route.params.templateId,
            templateConfig: {},
            isEditMode: false,
        };
    },

    methods: {
        async onDeleteConfirmed() {
            await this.$store.dispatch("application/deleteTemplateConfig", {
                templateConfigId: this.templateConfigId,
            });
            this.$router.push({
                name: "AdminApplication",
                applicationId: this.applicationId,
            });
        },
    },

    async beforeMount() {
        this.templateConfig = await this.$store.dispatch(
            "application/getTemplateConfig",
            {
                templateConfigId: this.templateConfigId,
            },
        );
    },
};
</script>

<style lang="scss" scoped></style>
